<template>
  <div>
    <NavBar />
    <error-page />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import ErrorPage from "../components/ErrorPage";

export default {
  name: "error",

  components: {
    NavBar,
    ErrorPage,
  },
};
</script>
